<template>
  <div style="background:#3019bd;">
    <div class="consult-section2" v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }"  style="display: flex;flex-flow:column nowrap; justify-content:center;position: relative;padding:0 110px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;height: 800px;">

        <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;">MyDepot</div>
        <div style="font-size: 32px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 600;margin-top:20px;">我们的联系方式</div>
        <div class="distribution-column" style="display:flex;flex-flow:row nowrap; align-items:center;margin-top:65px;">
            <div style="display:flex;flex-flow:column nowrap;flex:1;text-align:left;">
                <!-- <div style="font-size: 32px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 600;text-align:left;">中国总部 </div>
                <div style="font-size: 18px;color: #FFFFFF;letter-spacing: 0;line-height: 30px;font-weight: 500;margin-top:30px;">深圳千亚国际供应链科技有限公司 </div>
                <div style="font-size: 14px;color: #C8C5FF;letter-spacing: 0;line-height: 30px;font-weight: 400;">电话：0755-83997996</div>
                <div style="font-size: 14px;color: #C8C5FF;letter-spacing: 0;line-height: 30px;font-weight: 400;">邮箱：lala@mydepot.cn</div>
                <div style="font-size: 14px;color: #C8C5FF;letter-spacing: 0;line-height: 30px;font-weight: 400;">地址：广东省深圳市福田区八卦五街  锦文创客大厦4楼</div> -->
            </div>
            <div style="flex:1;">
              <img src="@/assets/img/business-consult/03业务咨询02二维码.png"/>
            </div>
            <div class="section2-top" style="display:flex;flex-flow:column nowrap;flex:1;text-align:right;">
                <div style="font-size: 32px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 600;text-align:right;">美国总部 </div>
                <div style="font-size: 18px;color: #FFFFFF;letter-spacing: 0;line-height: 30px;font-weight: 500;margin-top:30px;">MyDepot Inc  </div>
                <div style="font-size: 14px;color: #C8C5FF;letter-spacing: 0;line-height: 30px;font-weight: 400;">Number：+1(678)685 1029  </div>
                <div style="font-size: 14px;color: #C8C5FF;letter-spacing: 0;line-height: 30px;font-weight: 400;">Email： Info@mydepot.com       </div>
                <div style="font-size: 14px;color: #C8C5FF;letter-spacing: 0;line-height: 30px;font-weight: 400;">Office：1826 Doan Way STE B, Norcross, GA 30093</div>
            </div>
        </div>
    </div>
  </div>
</template>
  
  <script>
    import bgImage from '@/assets/img/business-consult/03业务咨询02bg@2x.png';
    
  export default {
    name: 'ConsultSection2',
    components:{
      
    },
    data:function() {
      return {
          bgImage
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  