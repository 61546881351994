<template>
    <div class="distribution-section4" style="display: flex;flex-flow:row nowrap; justify-content:center;margin:200px 0 100px;">
      <div class="left" style="display: flex;flex-flow:column nowrap; justify-content:center;text-align:left;width:451px;margin:0 6% 0 8%;">
          <img src="@/assets/img/distribution/distribution-section4/02分销商城01mydepot海外仓04数字.png" style="width:420px"/>
          <div style="font-size: 40px;color: #391BF1;letter-spacing: 0;line-height: 64px;font-weight: 600;margin: 30px 0 20px">美国自营仓</div>
          <div class="section4-left-text" style="font-size: 24px;color: #333333;letter-spacing: 0;line-height: 48px;font-weight: 400;">
            专设TikTok海外仓10万平方尺，更适用于TikTok卖家的海外仓服务，可提供标准仓配服务及定制化解决方案。
          </div>
      </div>

      <div class="right" style="display: flex;flex-flow:column nowrap; justify-content:center;flex:1;">
        <div class="distribution-column" style="display:flex;flex-flow:row nowrap;align-items:center;justify-content:center;"> 
             <div class="margin-right0" v-bind:style="{ 'background-image': 'url(' + bgImage1 + ')' }" style="position:relative;width: 256px;height: 256px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
                <div style="position: absolute;bottom:14px;left:0;right:0;text-align: left;padding: 0 16px;">
                  <div>
                    <span style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 600;">CA - </span>
                    <span style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">1551 S Lilac Ave, </span>
                  </div>
                  <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">
                    Bloomington,CA 92316 
                  </div>
                </div>
             </div>

             <div class="margin-right0" v-bind:style="{ 'background-image': 'url(' + bgImage2 + ')' }" style="position:relative;width: 256px;height: 256px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin: 0 30px;">
                <div style="position: absolute;bottom:14px;left:0;right:0;text-align: left;padding: 0 16px;">
                  <div>
                    <span style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 600;">SC - </span>
                    <span style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">874 Paragon </span>
                  </div>
                  <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">
                    Way,Rock Hill,SC 29730 
                  </div>
                </div>
             </div>

             <div class="margin-right0" v-bind:style="{ 'background-image': 'url(' + bgImage3 + ')' }" style="position:relative;width: 256px;height: 256px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
                <div style="position: absolute;bottom:14px;left:0;right:0;text-align: left;padding: 0 16px;">
                  <div>
                    <span style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 600;">NC - </span>
                    <span style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;"> 635 King St, </span>
                  </div>
                  <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">
                    Reidsville,NC 27320 
                  </div>
                </div>
             </div>

          </div>
          
          <div class="distribution-column" style="display:flex;flex-flow:row nowrap;align-items:center;justify-content:center;margin-top:30px;"> 
            
            <div class="margin-right0" v-bind:style="{ 'background-image': 'url(' + bgImage4 + ')' }" style="position:relative;width: 256px;height: 256px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
                <div style="position: absolute;bottom:14px;left:0;right:0;text-align: left;padding: 0 16px;">
                  <div>
                    <span style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 600;">GA - </span>
                    <span style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">4131 Southmeadow Pkwy W,</span>
                  </div>
                  <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">
                    Atlanta, GA 30349  
                  </div>
                </div>
             </div>

             <div class="margin-right0" v-bind:style="{ 'background-image': 'url(' + bgImage5 + ')' }" style="position:relative;width: 256px;height: 256px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin: 0 30px;">
                <div style="position: absolute;bottom:14px;left:0;right:0;text-align: left;padding: 0 16px;">
                  <div>
                    <span style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 600;">TX -  </span>
                    <span style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">737 S Cravens </span>
                  </div>
                  <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">
                    Missouri City,TX 77489
                  </div>
                </div>
             </div>

             <div class="margin-right0" v-bind:style="{ 'background-image': 'url(' + bgImage6 + ')' }" style="position:relative;width: 256px;height: 256px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
                <div style="position: absolute;bottom:14px;left:0;right:0;text-align: left;padding: 0 16px;">
                  <div>
                    <span style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 600;">NJ -  </span>
                    <span style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">1950 River Road,  </span>
                  </div>
                  <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 24px;font-weight: 400;">
                    Burlington, NJ 08016
                  </div>
                </div>
             </div>
             
          </div>
      </div>

    </div>
  </template>
  
  <script>
  import bgImage1 from '@/assets/img/distribution/distribution-section4/02分销商城01mydepot海外仓04地址01.png'
  import bgImage2 from '@/assets/img/distribution/distribution-section4/02分销商城01mydepot海外仓04地址02.png'
  import bgImage3 from '@/assets/img/distribution/distribution-section4/02分销商城01mydepot海外仓04地址03.png'
  import bgImage4 from '@/assets/img/distribution/distribution-section4/02分销商城01mydepot海外仓04地址04.png'
  import bgImage5 from '@/assets/img/distribution/distribution-section4/02分销商城01mydepot海外仓04地址05.png'
  import bgImage6 from '@/assets/img/distribution/distribution-section4/02分销商城01mydepot海外仓04地址06.png'
  export default {
    name: 'DistributionSection4',
    components:{
      
    },
    data:function() {
      return {
                bgImage1,
                bgImage2,
                bgImage3,
                bgImage4,
                bgImage5,
                bgImage6,
            };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  