<template>
    <div class="distribution-section4" style="display:flex;flex-flow:row nowrap; padding:0 15% 110px;align-items: flex-start;background: #F7FAFF;">
      <div class="left"><img src="@/assets/img/quick-contact/02分销商城02达人快速建联02配图03.png"/></div>
      <div class="right" style="display:flex;flex-flow:column nowrap;text-align:left;height:230px;margin-top: 100px;margin-left: 205px;background-repeat: no-repeat;background-position: center; " v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }" >
          <div style="min-width: 558px;margin-top: 130px;">
            <div style="font-size: 40px;color: #391BF1;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-left: 80px;">达人建联管理系统</div>
            <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-top:40px;margin-left: 80px;">
              数据实时监测，建联流程智能化，
            </div>
            <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-left: 80px;">
              无需经验背书，助你高效挖掘优质带货达人
            </div>
          </div>
        </div>   
    </div>
  </template>
  
  <script>
  import bgImage from "@/assets/img/quick-contact/02分销商城02达人快速建联02圆形背景.png"
  export default {
    name: 'DistributionSection4',
    components:{
      
    },
    data:function() {
      return {
                bgImage
            };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  