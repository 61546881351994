<template>
    <div class="home-section2">
        <img src="@/assets/img/home/home-section2/00header-logo-blue.png"/>
        <div data-wow-duration="2s" class="wow animate__animated animate__fadeInRight" style="padding: 32px 60px;">
          <span style="width: 100px;display: inline-block;"></span>
          <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;">
            MyDepot是一家行业领先的TikTok美区最大货盘-达人撮合平台，为国内品牌卖家提供全链条的B端渠道服务。
          </div>
          <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;">
            公司总部位于美国乔治亚州，充分发挥美国本土优势，旗下签约垂类网红500+，合作达人5000+，TikTok优质网红资源35000+，服务品牌50+。
          </div>
          <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;">
            从建联达人短视频带货，到直播代播，海外仓发货，MyDepot提供一站式TikTok销售解决方案，助力卖家在TikTok上实现销售和业务腾飞。
          </div>
        </div>
        <div class="section2-img" style="display:flex;flex-flow:row nowrap; justify-content:center;align-items:center;">
          <div class="section2-img-item wow animate__animated animate__bounceIn" v-bind:style="{ 'background-image': 'url(' + bgImage1 + ')' }" style="display: flex;justify-content: center;position:relative;width: 378px;height: 478px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
            <div style="position: absolute; bottom:16px;padding: 30px;">    
              <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;">本土运营团队</div>
              <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                本土直播运营团队，内容创作团队，
              </div>
              <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                网红管理团队，为卖家提供定制化销售方案
              </div>
            </div>
          </div>
          <div class="section2-img-item wow animate__animated animate__bounceIn" v-bind:style="{ 'background-image': 'url(' + bgImage2 + ')' }" style="display: flex;justify-content: center;position:relative;width: 378px;height: 478px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin:0 10px;">
            <div style="position: absolute; bottom:16px;padding: 30px;">    
              <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;">达人快速建联</div>
              <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                海量达人库，自动化系统一键触达，
              </div>
              <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                帮助卖家快速推广
              </div>
            </div>
          </div>
          <div class="section2-img-item wow animate__animated animate__bounceIn" v-bind:style="{ 'background-image': 'url(' + bgImage3 + ')' }" style="display: flex;justify-content: center;position:relative;width: 378px;height: 478px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
            <div style="position: absolute; bottom:16px;padding: 30px;">    
              <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;">直播代播</div>
              <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                本土直播间，签约主播，
              </div>
              <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                定制化直播方案，助力卖家快速起量
              </div>
            </div>
          </div>
        </div>
 
    </div>
  </template>
  
  <script>
  import bgImage1 from '@/assets/img/home/home-section2/01首页02背景01@2x.png';
import bgImage2 from '@/assets/img/home/home-section2/01首页02背景02@2x.png';
import bgImage3 from '@/assets/img/home/home-section2/01首页02背景03@2x.png';
  export default {
    name: 'HomeSection2',
    components:{
      
    },
    data:function() {
      return {
                bgImage1,
                bgImage2,
                bgImage3,
            };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
 
  </style>
  