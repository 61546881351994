<template>
    <div class="distribution-section6" style="margin-top:110px;">
        <div style="display: flex;flex-flow:column nowrap; justify-content:center">
          <div style="font-size: 32px;
          color: #391BF1;
          letter-spacing: 0;
          text-align: center;
          line-height: 32px;
          font-weight: 600;margin-top:8px;">全链路解决方案</div>
          <div class="distribution-column" style="display:flex;flex-flow:row nowrap;align-items:baseline;justify-content:center;margin-top:60px;"> 
             <div style="text-align: center;display: table-cell;width:380px;padding: 0 31px;">
                <img src="@/assets/img/distribution/distribution-section6/02分销商城01mydepot海外仓06图标01.png" style="width:95px;"/>
                <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center; font-weight: 600;margin-top:41px;">本土海外仓</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:21px;">
                  自有本土UPS/FedEx等折扣账号
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  一件代发，标准规范化电商操作
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  拓宽销售渠道，提升商品周转率
                </div>
             </div>

             <div style="text-align: center;display: table-cell;width:380px;padding: 0 31px;">
                <img src="@/assets/img/distribution/distribution-section6/02分销商城01mydepot海外仓06图标02.png" style="width:95px;"/>
                <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center; font-weight: 600;margin-top:41px;">全面仓配服务</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:21px;">
                  24H内出物流单号，2-7天极速妥投
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  全类型货架仓，库内操作流转度高
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  各仓可容纳托盘数5000+，货柜数400+
                </div>
             </div>

             <div style="text-align: center;display: table-cell;width:380px;padding: 0 31px;">
                <img src="@/assets/img/distribution/distribution-section6/02分销商城01mydepot海外仓06图标03.png" style="width:95px;"/>
                <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center; font-weight: 600;margin-top:41px;">智能管理系统</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:21px;">
                  智能化仓配WMS操作管理系统
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  完善的订单管理系统，货物全程追踪
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  标准库内作业规范化流程，无隐藏收费
                </div>
             </div>

          </div>
          <div class="distribution-column" style="display:flex;flex-flow:row nowrap;align-items:baseline;justify-content:center;margin-top:60px;"> 
             <div style="text-align: center;display: table-cell;width:380px;padding: 0 31px;">
                <img src="@/assets/img/distribution/distribution-section6/02分销商城01mydepot海外仓06图标04.png" style="width:95px;"/>
                <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center; font-weight: 600;margin-top:41px;">平台对接支持</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:21px;">
                  多电商平台API对接，实时同步销售订单
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  按客户要求定制系统接口对接
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  优质服务团队，IT技术保障
                </div>
             </div>

             <div style="text-align: center;display: table-cell;width:380px;padding: 0 31px;">
                <img src="@/assets/img/distribution/distribution-section6/02分销商城01mydepot海外仓06图标05.png" style="width:95px;"/>
                <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center; font-weight: 600;margin-top:41px;">专属客户服务</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:21px;">
                  优质尾程运力和仓配全方位服务
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  支持海外仓个性定制化服务推荐
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  专人对接，国内外团队全流程接力
                </div>
             </div>

             <div style="text-align: center;display: table-cell;width:380px;padding: 0 31px;">
                <img src="@/assets/img/distribution/distribution-section6/02分销商城01mydepot海外仓06图标06.png" style="width:95px;"/>
                <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center; font-weight: 600;margin-top:41px;">多样增值服务</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:21px;">
                  商品拍照/包装/组装服务
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  商品开箱//检验/销毁服务
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:8px;">
                  贴换标等其他多样化服务
                </div>
             </div>

          </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DistributionSection6',
    components:{
      
    },
    data:function() {
      return {
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  