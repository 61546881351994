<template>
    <div class="distribution-section3 live-section3" v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }" 
    style="display: flex;flex-flow:column nowrap; justify-content:center;padding:110px 190px;height: 740px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;height: 1100px;">
      <div style="font-size: 16px;color: #391BF1;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;">MyDepot</div>
      <div style="font-size: 32px;color: #391BF1;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 600;margin-top:16px;">本土运营团队构成</div>
      <div class="distribution-column" style="display: flex;flex-flow:row nowrap; justify-content:center;">
        <div class="left" style="display: flex;flex-flow:column nowrap; justify-content:center;flex:1; padding: 55px 120px;">
          
          <div style="display: flex;flex-flow:column nowrap; justify-content:center;">
              <div style="display: flex;flex-flow:row nowrap; text-align:left; align-items:center;">
                <img src="@/assets/img/live-broadcast/02分销商城03直播代播03标题.png" style="width: 47px;height: 41px;"/>
                <div style="font-size: 32px;color: #333333;letter-spacing: 0;line-height: 56px;font-weight: 400;margin-left:29px;">直播运营团队</div>
              </div>
              <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 56px;font-weight: 400;margin-top:16px;text-align: left;width: 665px;">本土直播运营团队有2年TikTok直播带货经验，均是经过长时间培训和实操的海外员工。</div>
          </div>

          <div style="display: flex;flex-flow:column nowrap; justify-content:center; margin: 40px 0;">
            <div style="display: flex;flex-flow:row nowrap; text-align:left; align-items:center;">
                <img src="@/assets/img/live-broadcast/02分销商城03直播代播03标题.png" style="width: 47px;height: 41px;"/>
                <div style="font-size: 32px;color: #333333;letter-spacing: 0;line-height: 56px;font-weight: 400;margin-left:29px;">内容创作团队</div>
              </div>
              <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 56px;font-weight: 400;margin-top:16px;text-align: left;width: 665px;">
                团队成员均是电影、音乐、媒体专业出身，有3年以上的海外短视频制作经验，熟知各个领域的热门玩法，擅长本地化短视频的脚本策划、制作、拍摄、剪辑。
              </div>
          </div>

          <div style="display: flex;flex-flow:column nowrap; justify-content:center;margin: 40px 0;">
            <div style="display: flex;flex-flow:row nowrap; text-align:left; align-items:center;">
                <img src="@/assets/img/live-broadcast/02分销商城03直播代播03标题.png" style="width: 47px;height: 41px;"/>
                <div style="font-size: 32px;color: #333333;letter-spacing: 0;line-height: 56px;font-weight: 400;margin-left:29px;">网红管理团队</div>
              </div>
              <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 56px;font-weight: 400;margin-top:16px;text-align: left;width: 665px;">
                负责链接品牌与网红的广告与直播排期，能够快速理解整理品牌需求，并迅速匹配到最适合的网红，传达至网红落地执行。
              </div>
          </div>
 
      </div>

      <div class="right" style="display: flex;flex-flow:column nowrap; justify-content:center;width:600px;">
         <img src="@/assets/img/live-broadcast/02分销商城02达人快速建联02二维码.png" style="width:333px;height: 402px;margin: auto;border: 1px #6666667a solid;border-radius: 26px;"/>
      </div> 
    </div>
     
    </div>
  </template>
  
  <script>
   import bgImage from '@/assets/img/live-broadcast/02分销商城03直播代播03背景@2x.png';
  export default {
    name: 'DistributionSection3',
    components:{
      
    },
    data:function() {
      return {
                bgImage
            };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  