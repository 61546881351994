<template>
    <div class="home-section5" style="padding: 110px 190px;">
        <div style="display: flex;flex-flow:column nowrap; justify-content:center">
        <div style="font-size: 16px;
          color: #391BF1;
          letter-spacing: 0;
          text-align: center;
          line-height: 32px;
          font-weight: 400;">MyDepot</div>
          <div style="font-size: 32px;
          color: #391BF1;
          letter-spacing: 0;
          text-align: center;
          line-height: 32px;
          font-weight: 600;margin-top:20px;">合作案例</div>
           <!-- <a-carousel arrows autoplay> -->
           <a-carousel arrows>
              <div>          
                <div class="img-bg" v-bind:style="{ 'background-image': 'url(' + bgImage1 + ')' }" style="position:relative;width: 1091px;height: 520px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;display: flex;flex-flow:row nowrap; justify-content:center;margin:45px auto 0px;">
                  <div class="img-text" style="position: absolute;top:80px;left: 335px;right: 62px;text-align: left;">
                      <div class="img-text-big" style="font-size: 32px;color: #FFFFFF;letter-spacing: 0;line-height: 56px;font-weight: 600;">合作品牌: 尤黛UDAER</div>
                      <div class="img-text-mid" style="font-size: 24px;color: #FFFFFF;letter-spacing: 0;line-height: 56px;font-weight: 600;margin-top:5px;">
                        策划内容: 短视频策划、直播策划
                      </div>
                      <div class="img-text-small" style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:32px;">
                        从零打造品牌专属直播间，搭配美国洛杉矶专业直播运营团队，
                      </div>
                      <div class="img-text-small" style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;">
                        助力品牌进军北美电商直播市场。本土化短视频运营团队，品牌定制化脚本输出，周期性短视频数据分析，打造独具品牌特色的视频内容，定期提供旗下签约网红短视频/直播引流增值服务，助力节假日品牌大促，提升货品销量。
                      </div>
                  </div>
                </div>
                
                <div style="display:flex;flex-flow:column nowrap;align-items:center;justify-content: space-between;margin-top: -35px;z-index: 2;position: relative;">
                  <div class="carousel-bottom" style="display:flex;flex-flow:row nowrap;align-items:center;justify-content: space-between;width: 1160px;margin: 0 auto;padding-left: 80px;">
                  <div class="carousel-bottom-item" style="display:flex;flex-flow:column nowrap;text-align:left;width: 20%;">
                    <div style="font-size: 24px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">品牌关注度提升</div>
                    <div style="font-size: 64px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">500W+</div>
                  </div> 
                  <div class="carousel-bottom-item" style="display:flex;flex-flow:column nowrap;text-align:left;width: 20%;">
                    <div style="font-size: 24px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">直播场次 </div>
                    <div style="font-size: 64px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">20场+</div>
                  </div> 
                  <div class="carousel-bottom-item" style="display:flex;flex-flow:column nowrap;text-align:left;width: 20%;">
                    <div style="font-size: 24px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">短视频拍摄 </div>
                    <div style="font-size: 64px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">60条+</div>
                  </div> 
                  <div class="carousel-bottom-item" style="display:flex;flex-flow:column nowrap;text-align:left;width: 20%;">
                    <div style="font-size: 24px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">网站访问量</div>
                    <div style="font-size: 64px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">80W+</div>
                  </div> 
                  
                </div>
                <div style="background: #391BF1;height: 8px;border-radius: 4px;width: 1030px;margin-left: 35px;margin-top: 12px;"></div>
                </div>

              </div>

              <div>          
                <div class="img-bg" v-bind:style="{ 'background-image': 'url(' + bgImage2 + ')' }" style="position:relative;width: 1091px;height: 520px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;display: flex;flex-flow:row nowrap; justify-content:center;margin:45px auto 0px;">
                  <div class="img-text img-text-page2" style="position: absolute;top:80px;left: 335px;right: 62px;text-align: left;">
                      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 600;">
                        通过达人建联短视频带货快速销售亚马逊下架库存。
                      </div>
                      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 600;">
                        某电子商家无人机产品被亚马逊平台下架，被迫将大批量货盘转移至我们海外仓
                      </div>
                      <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top: 16px;">快速对接产出：</div>
                      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;">
                        我们立即与超过50位电子领域达人合作，快速创作100条+本地自拍短视频及多场直播带货，更有
                      </div>
                      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;">
                        达人在我们美国海外仓现场直播带货
                      </div>
                      <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top: 16px;">达人效应：</div>
                      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;">
                        视频发布一周内达到22万+播放量，一周销售980笔订单，销量达到爆发性增长。
                      </div>
                      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;">
                        25天，我们通过合作达人、海外仓直播和本地自拍短视频，利用TikTok的流量迅速提升销量，帮助
                      </div>
                      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;">
                        客户以高于亚马逊的售价快速清空库存。
                      </div>
                  </div>
                </div>

                <div style="display:flex;flex-flow:column nowrap;align-items:center;justify-content: space-between;margin-top: -35px;z-index: 2;position: relative;">
                  <div class="carousel-bottom" style="display:flex;flex-flow:row nowrap;align-items:center;justify-content: space-between;width: 1160px;margin: 0 auto;padding-left: 80px;">
                  <div class="carousel-bottom-item" style="display:flex;flex-flow:column nowrap;text-align:left;width: 20%;">
                    <div style="font-size: 24px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">合作达人</div>
                    <div style="font-size: 64px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">50+</div>
                  </div> 
                  <div class="carousel-bottom-item" style="display:flex;flex-flow:column nowrap;text-align:left;width: 20%;">
                    <div style="font-size: 24px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">直播场次 </div>
                    <div style="font-size: 64px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">10场+</div>
                  </div> 
                  <div class="carousel-bottom-item" style="display:flex;flex-flow:column nowrap;text-align:left;width: 20%;">
                    <div style="font-size: 24px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">平均短视频播放量</div>
                    <div style="font-size: 64px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">20W+</div>
                  </div> 
                  <div class="carousel-bottom-item" style="display:flex;flex-flow:column nowrap;text-align:left;width: 20%;">
                    <div style="font-size: 24px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">销量</div>
                    <div style="font-size: 64px;color: #000000;letter-spacing: 0;line-height: 72px;font-weight: 600;">5W+</div>
                  </div> 
                  
                </div>
                <div style="background: #391BF1;height: 8px;border-radius: 4px;width: 1030px;margin-left: 35px;margin-top: 12px;"></div>
                </div>

              </div>
  
            </a-carousel>
          
        </div>
    </div>
  </template>
  
<script>
import bgImage1 from '@/assets/img/home/home-section5/01首页05背景01.png';
import bgImage2 from '@/assets/img/home/home-section5/01首页05背景02.png';
  // import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
   
  export default {
    name: 'HomeSection5',
    components:{
      // RightCircleOutlined,LeftCircleOutlined
    },
    data:function() {
      return {
                bgImage1,bgImage2
              };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   :deep(.slick-slide) {
  text-align: center;
  height: fit-content;
  line-height: 160px;
  /* background: #364d79; */
  overflow: hidden;
}

:deep(.slick-arrow.custom-slick-arrow) {
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: rgb(57, 27, 241);
  /* background: rgb(57, 27, 241); */
  transition: ease all 0.3s;
  opacity: 1;
  z-index: 1;
  border-radius: 15px;
}
:deep(.slick-arrow.custom-slick-arrow:before) {
  display: none;
}
:deep(.slick-arrow.custom-slick-arrow:hover) {
  color: rgb(57, 27, 241);
  opacity: 0.5;
}

:deep(.slick-slide h3) {
  color: #fff;
}

:deep(.ant-carousel .slick-dots li  ) {
  width: 10px;
}

:deep(.ant-carousel .slick-dots li button) {
  background: rgb(57, 27, 241);
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

:deep(.ant-carousel .slick-slider) {
  padding-bottom: 60px;;
}
</style>
  