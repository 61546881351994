<template>
    <div class="distribution-section8" style="display:flex;flex-flow:column nowrap;padding:110px 0;background: #F8F8F8;">
        <div style="font-size: 32px;color: #391BF1;letter-spacing: 0;line-height: 32px;font-weight: 600;">合作伙伴</div>
        <div class="distribution-column" style="display:flex;flex-flow:row nowrap;justify-content: center;margin-top:60px;">
          <img src="@/assets/img/distribution/distribution-section8/02分销商城01mydepot海外仓07logo.png"/>
          <img src="@/assets/img/distribution/distribution-section8/02分销商城01mydepot海外仓07其他.png" style="margin-left:100px;"/>
        </div>
    </div>
  </template>
  
  <script>
 
  export default {
    name: 'DistributionSection8',
    components:{
      
    },
    data:function() {
      return {
 
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  