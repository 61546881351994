<template>
    <div class="distribution-section3" style="display:flex;flex-flow:row nowrap;padding:0 15%;align-items: flex-start;background: #F7FAFF;">
      <div class="right" style="display:flex;flex-flow:column nowrap;text-align:left;height:230px;margin-top: 100px;margin-right: 120px;background-repeat: no-repeat;background-position: center; " v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }" >
          <div style="min-width: 558px;margin-top: 130px;">
            <div style="font-size: 40px;color: #391BF1;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-left: 80px;">自动化建联工具</div>
            <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-top:40px;margin-left: 80px;">
              深度开发潜力达人，批量对接资源，
            </div>
            <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-left: 80px;">
              自动化操作，为你节省成本
            </div>
          </div>
        </div>  
      <div class="left"><img src="@/assets/img/quick-contact/02分销商城02达人快速建联02配图02.png"/></div>
    </div>
  </template>
  
  <script>
  import bgImage from "@/assets/img/quick-contact/02分销商城02达人快速建联02圆形背景.png"
  export default {
    name: 'DistributionSection3',
    components:{
      
    },
    data:function() {
      return {
              bgImage
            };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  