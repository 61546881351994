<template>
    <div class="distribution-section5" style="display:flex;flex-flow:column nowrap;padding:90px 190px;background-image: radial-gradient(circle at 50% -30%, #354285 0%, #354285 74%, #E9F1FF 99%);height:1600px;">
      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;">MyDepot</div>
        <div style="font-size: 32px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 600;margin-top:16px;">达人风采展示</div>
        <div class="scrollx" style="display:flex;flex-flow:row nowrap;justify-content: center;margin-top:80px;">
          <img src="@/assets/img/quick-contact/02分销商城02达人快速建联03配图01.png" style="width: 280px;"/>
          <img src="@/assets/img/quick-contact/02分销商城02达人快速建联03配图02.png" style="width: 280px;margin-left:20px;"/>
          <img src="@/assets/img/quick-contact/02分销商城02达人快速建联03配图03.png" style="width: 280px;margin-left:20px;"/>
          <img src="@/assets/img/quick-contact/02分销商城02达人快速建联03配图04.png" style="width: 280px;margin-left:20px;"/>
          <img src="@/assets/img/quick-contact/02分销商城02达人快速建联03配图05.png" style="width: 280px;margin-left:20px;"/>
        </div>
        <div class="qrCode" style="width:100%;height:700px;display: flex;justify-content: center;align-items: center;margin-top:120px;">
            <img src="@/assets/img/quick-contact/02分销商城02达人快速建联02二维码@2x.png" style="width:400px"/>
        </div>
    </div>
  </template>
  
  <script>
 
  export default {
    name: 'DistributionSection5',
    components:{
      
    },
    data:function() {
      return {
 
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  