<template>
    <div class="home-section1" v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }" style="height: 920px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
      <div class="header">
        <div class="header-left"> 
        <div class="header-logo">
          <router-link to="/">
            <img class="section1-logo" src="@/assets/img/home/home-section1/00header-logo-theme-small.png" style="margin-top: 8px;"/>
            <img class="section1-logo-fixed" width="105" height="21" src="@/assets/img/home/home-section2/00header-logo-blue.png" style="margin-top: 8px;"/>
          </router-link>
        </div>
        <!-- <a-dropdown :trigger="['click']">
          <router-link to="/" class="ant-dropdown-link">业务介绍</router-link>
        </a-dropdown> -->
        <a-dropdown :trigger="['click']" placement="bottom">
          <a class="ant-dropdown-link" @click.prevent style="margin-left: 90px;font-size: 16px;color: #DCE2FF;letter-spacing: 0;font-weight: 400;border-bottom: none;text-decoration: none;">
            业务介绍
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0" class="dropdown-item">
                <router-link to="/warehouse" class="dropdown-item-link">MyDepot  海外仓</router-link>
              </a-menu-item>
              <a-menu-item key="1" class="dropdown-item">
                <router-link to="/quickcontact" class="dropdown-item-link">达人快速建联</router-link>
              </a-menu-item>
              <a-menu-item key="3" class="dropdown-item"><router-link to="/live" class="dropdown-item-link" >直播代播</router-link></a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a href="https://www.mydepot.com" style="margin-left: 90px;font-size: 16px;color: #DCE2FF;letter-spacing: 0;font-weight: 400;border-bottom: none;text-decoration: none;">分销商城</a>
        <a-dropdown :trigger="['click']">
            <router-link to="/consult" style="margin-left: 90px;font-size: 16px;color: #DCE2FF;letter-spacing: 0;font-weight: 400;border-bottom: none;text-decoration: none;">业务咨询</router-link>
        </a-dropdown>
        </div>
        <div class="header-right">
          <img src="@/assets/img/home/home-section1/00登录.png" style="margin-right: 10px;"/>
          <a-button class="home-login"><a href="https://www.mydepot.com/#/login" class="login-link">登录</a></a-button>
        </div>
      </div>
      
      <div class="description">
          <div class="description-left animate__animated animate__fadeInLeft">
            <img src="@/assets/img/home/home-section1/00header-logo-white@2x.png" style="width:251px;"/>
             <span class="description-left-title">TikTok 美区最大货盘 - 达人撮合平台</span>
             <label class="description-left-desc">百万尺海外仓 | 百万级深度库存 | 本土直播代播 | 本土达人快速建联</label>
          </div>
          <div class="description-right animate__animated animate__fadeInRight"  style="margin-left: 30px;background: #FFFFFF;box-shadow: inset 0px 0px 24px 0px rgba(193,220,255,1);border-radius: 40px;display:flex;flex-flow:column nowrap;padding: 40px;width: 500px;">
              <div style="font-size: 22px;color: #391BF1;letter-spacing: 0;text-align: center;line-height: 22px;font-weight: 600;text-align:left">
                成为供货商
              </div>
              <div style="text-align:left;margin-top:16px;">
                <span style="font-size: 16px;color: #333333;letter-spacing: 0;line-height: 16px;font-weight: 400;">已有 MyDepot 账号？</span>
                <a-button class="home-login" style="border: 1px solid #391BF1;border-radius: 14px;padding: 1px 8px;"><a href="https://www.mydepot.com/#/register?identityType=Sup" class="login-link">登录</a></a-button>
              </div>
              <HomeForm />
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import bgImage from '@/assets/img/home/home-section1/01首页01背景@2x.png';
  import HomeForm from '@/components/home/HomeForm.vue';
  export default {
    name: 'HomeSection1',
    components:{
      HomeForm,
    },
    data:function() {
      return {
                bgImage
            };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
  .home-section1 {
    position: relative;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  .section1-image1 {
    position:absolute;
    top: 0;
    height:800px;
  }
  .header {
    display: flex;
    flex-flow: row nowrap;
    width:100%;
    padding: 30px 120px;
    z-index: 2;
    height:80px;
  }
  .ant-dropdown-link{
    margin-left: 120px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 600;
    border-bottom: 2px white solid;
    text-decoration: none;
  }
  .dropdown-item-link {
    display: block;
    padding: 15px;
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
    font-weight: 200;
  }
   
  .header-left {
    display: flex;
    align-items: center;
    flex:1;
  }
  .header-right {
    width:auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-login {
    background: #FFFFFF;
    border-radius: 12px;
    font-size: 12px;
    color: #391BF1;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding:2px 8px;
    height: unset;
  }
  .description {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: 100%;
    margin-top: 100px;
    padding: 0px 190px;
  }
  .description-left {
    display:flex;
    flex-flow:column;
  }
  .description-left-title {
    display:flex;  
    font-size: 28px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 500;
    margin-top: 42px;
  }
  .description-left-desc {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 500;text-align:left;margin-top:31px;
  }
  .login-link:hover {
    color: #391BF1;
  }
  </style>
  