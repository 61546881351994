<template>      
  <div class="header" style="height:80px;">
    <div class="header-left"> 
      <div class="header-logo">
        <router-link to="/">
          <img class="section1-logo" width="105" height="21" src="@/assets/img/home/home-section2/00header-logo-blue.png" style="margin-top: 8px;"/>
          <img class="section1-logo-fixed" width="105" height="21" src="@/assets/img/home/home-section2/00header-logo-blue.png" style="margin-top: 8px;"/>
        </router-link>
      </div>
    <!-- <a-dropdown :trigger="['click']">
      <router-link to="/" class="ant-dropdown-link" style="text-decoration:none;margin-left: 120px;">业务介绍</router-link>
    </a-dropdown> -->
    <a-dropdown :trigger="['click']" placement="bottom">
      <a class="ant-dropdown-link" @click.prevent style="color: #391BF1;  border-bottom: 2px #391BF1 solid;font-weight: 600;">
        业务介绍
        <DownOutlined />
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item key="0" class="dropdown-item">
            <router-link to="/warehouse" class="dropdown-item-link" >MyDepot  海外仓</router-link>
          </a-menu-item>
          <a-menu-item key="1" class="dropdown-item">
            <router-link to="/quickcontact" class="dropdown-item-link" style="color: #391BF1;font-weight: 400;">达人快速建联</router-link>
          </a-menu-item>
          <a-menu-item key="3" class="dropdown-item"><router-link to="/live" class="dropdown-item-link" >直播代播</router-link></a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <a href="https://www.mydepot.com" class="dropdown-item-link" style="margin-left:90px;text-decoration: none;font-weight: 400;">分销商城</a>
    <a-dropdown :trigger="['click']">
      <router-link to="/consult" class="dropdown-item-link" style="margin-left:90px;text-decoration: none;font-weight: 400;">业务咨询</router-link>
    </a-dropdown>
    </div>
    <div class="header-right">
      <img src="@/assets/img/distribution/distribution-section1/00登录blue.png" style="margin-right: 10px;"/>
      <a-button class="distribution-login"><a href="https://www.mydepot.com/#/login" class="login-link">登录</a></a-button>
    </div>
  </div>
  <div class="distribution-section1" v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }" style="height: 740px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
      <div class="description">
          <div class="description-left animate__animated animate__fadeInLeft" style="text-align:left;">
              <img src="@/assets/img/home/home-section1/00header-logo-white@2x.png" style="width:257px;"/>
              <div style="font-size: 32px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:42px;">TikTok达人快速建联</div>
              <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:38px;">海量达人资源，自动化建联工具，达人管理系统，助你建联达人快人一步</div>
          </div>
          <div class="description-right animate__animated animate__fadeInRight"  style="width: 500px;">
              <img src="@/assets/img/quick-contact/affiliate.gif" style="width: 308px;height:544px;border-radius: 15px;"/>
          </div>
      </div>
  </div>
</template>
  
  <script>
  import bgImage from '@/assets/img/quick-contact/02分销商城02达人快速建联01背景@2x.png';
  export default {
    name: 'DistributionSection1',
    components:{
    },
    data:function() {
      return {
               bgImage
            };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
  .distribution-section1 {
    position: relative;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  .section1-image1 {
    position:absolute;
    top: 0;
    height:800px;
  }
  .header {
    display: flex;
    flex-flow: row nowrap;
    width:100%;
    padding: 30px 120px;
    z-index: 2;
    height: 50px;
  }
  .ant-dropdown-link{
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
    font-weight: 400;
    margin-left: 90px;
  }
  .dropdown-item-link {
    display: block;
    padding: 15px;
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
    font-weight: 200;
  }
   
  .header-left {
    display: flex;
    align-items: center;
    flex:1;
  }
  .header-right {
    width:auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .distribution-login {
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding:3px 8px;
    height: unset;
    background: #391BF1;
    border-radius: 12px;
  }
  .description {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: 100%;
    margin-top: 100px;
    padding: 0px 190px;
  }
  .description-left {
    display:flex;
    flex-flow:column;
  }
  .description-left-title {
    display:flex;  
    font-size: 28px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 500;
    margin-top: 42px;
  }
  .description-left-desc {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 500;text-align:left;margin-top:31px;
  }
  .login-link:hover {
    color:#FFFFFF
  }
  </style>
  