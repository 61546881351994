<template>
    <div class="distribution-section3" style="margin-top:110px;background: #6C58E6;padding: 110px 8%;">
      <div class="distribution-column" style="display: flex;flex-flow:row nowrap; justify-content:center;">
            <div class="left section3-padding" style="display:flex;flex-flow:column nowrap;flex:1;text-align:left;flex:2;margin:100px 100px 0;min-width: 660px;">
                <div style="font-size: 40px;color: #FFFFFF;letter-spacing: 0;line-height: 64px;font-weight: 600;">美国海外仓</div>
                <div style="font-size: 40px;color: #FFFFFF;letter-spacing: 0;line-height: 64px;font-weight: 600;margin-top:8px;">一站式跨境物流服务</div>
                <div style="font-size: 18px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:33px;">
                  MyDepot Inc总部位于美国佐治亚州，拥有7座自营海外仓，总面积达百万尺，分别坐落于加利福利亚州、得克萨斯州、佐治亚州、南卡罗来纳州、北卡罗来纳州、新泽西州。
                </div>
                <div style="display:flex;flex-flow:row nowrap; align-items:flex-start;margin-top:25px;">
                  <!-- <img src="@/assets/img/distribution/distribution-section3/02分销商城01mydepot海外仓03照片@2x.png" style="width: 227px;"/> -->
                  <div style="font-size: 18px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;">
                    海外仓分布于美国东西中部，毗邻各大重要海港，周围被亚马逊仓库环绕，取送货方便快捷，地理位置优越，尾程渠道资源丰富，派送覆盖面广，仓库采用先进专业的海外仓管理系统，最大程度满足客户物流时效需求。
                  </div>
                </div>
                <div style="font-size: 18px;color: #FFFFFF;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:25px;">
                  专设TikTok海外仓10万平方尺，更适用于TikTok卖家的海外仓服务，可提供标准仓配服务及定制化解决方案。</div>
            </div>
            
            <div class="right" style="display:flex;flex-flow:column nowrap;margin-right:100px;">
                <img src="@/assets/img/distribution/distribution-section3/02分销商城01mydepot海外仓03地图.png"  />
                <div class="right-flex" style="display:flex;flex-flow:row nowrap;margin-top:30px;">
                  <div style="display:table;text-align:left;">
                    <img src="@/assets/img/distribution/distribution-section3/02分销商城01mydepot海外仓03图标地球.png" style="width:135px;"/>
                    <div class="right-text" style="font-size: 40px;color: #FFFFFF;letter-spacing: 0;line-height: 44px;font-weight: 600;text-align:left;margin-top:16px;">100万尺</div>
                    <div class="right-text-small" style="font-size: 24px;color: #FFFFFF;letter-spacing: 0;line-height: 44px;font-weight: 600;margin-top:10px;">全美控仓面积</div>
                  </div>
                  
                  <div style="display:table;margin-left: 66px;">
                    <img src="@/assets/img/distribution/distribution-section3/02分销商城01mydepot海外仓03图标仓库.png" style="width:151px;"/>
                    <div class="right-text" style="font-size: 40px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 44px;font-weight: 600;margin-top:16px;">7座</div>
                    <div class="right-text-small" style="font-size: 24px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 44px;font-weight: 600;margin-top:10px;">海外仓</div>
                  </div>
                </div>
            </div>
          </div>
    </div>
  </template>
  
  <script>
 
  export default {
    name: 'DistributionSection3',
    components:{
      
    },
    data:function() {
      return {
 
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  