<template>
    <div class="home-section3" style="margin-top:70px;">
        <div style="display: flex;flex-flow:column nowrap; justify-content:center">
        <div style="font-size: 16px;
          color: #391BF1;
          letter-spacing: 0;
          text-align: center;
          line-height: 32px;
          font-weight: 400;">MyDepot</div>
          <div style="font-size: 32px;
          color: #391BF1;
          letter-spacing: 0;
          text-align: center;
          line-height: 32px;
          font-weight: 600;margin-top:8px;">服务内容</div>
          <div class="section3-img" style="display:flex;flex-flow:row nowrap;align-items:baseline;justify-content:center;margin-top:60px;"> 
             <div class="wow animate__animated animate__zoomIn" style="text-align: center;display: table-cell;width:425px;padding: 0 32px;">
               <img src="@/assets/img/home/home-section3/01首页03图标01.png" style="width:95px"/>
               <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;margin-top:22px;">本土海外仓</div>
               <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                7座自营海外仓，总面积达百万尺。
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                  支持多平台一件代发，退货换标，库内分销，
                </div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                订单24小时内出库
                </div>
             </div>

             <div class="wow animate__animated animate__zoomIn" style="text-align: center;display: table-cell;width:425px;padding: 0 32px;">
               <img src="@/assets/img/home/home-section3/01首页03图标02.png" style="width:95px"/>
               <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;margin-top:22px;">高效物流</div>
               <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                自有FedEx/UPS/USPS一手账号，</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                运费低廉，</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                2-7天极速妥投</div>
             </div>

             <div class="wow animate__animated animate__zoomIn" style="text-align: center;display: table-cell;width:425px;padding: 0 32px;">
               <img src="@/assets/img/home/home-section3/01首页03图标03.png" style="width:95px"/>
               <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;margin-top:22px;">定制方案</div>
               <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                为每个卖家定制销售方案，</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                紧跟官方政策和福利，</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                打破信息壁垒</div>
             </div>
          
          </div>
          <div class="section3-img" style="display:flex;flex-flow:row nowrap;align-items:baseline;justify-content:center;margin-top:60px;"> 
             <div class="wow animate__animated animate__zoomIn" style="text-align: center;display: table-cell;width:425px;padding: 0 32px;">
               <img src="@/assets/img/home/home-section3/01首页03图标04.png" style="width:95px"/>
               <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;margin-top:22px;">建联达人</div>
               <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                自开发建联工具，实现达人建联全自动化，</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                节省人工，提高效率</div>
             </div>

             <div class="wow animate__animated animate__zoomIn" style="text-align: center;display: table-cell;width:425px;padding: 0 32px;">
               <img src="@/assets/img/home/home-section3/01首页03图标05.png" style="width:95px"/>
               <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;margin-top:22px;">直播代播</div>
               <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                专业直播团队，实景直播间，</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;">
                签约主播，提供专场/混场直播</div>
             </div>

             <div class="wow animate__animated animate__zoomIn" style="text-align: center;display: table-cell;width:425px;padding: 0 32px;">
               <img src="@/assets/img/home/home-section3/01首页03图标06.png" style="width:95px"/>
               <div style="font-size: 24px;color: #333333;letter-spacing: 0;text-align: center;font-weight: 600;margin-top:22px;">美国本土货盘</div>
               <div style="font-size: 18px;color: #666666;letter-spacing: 0;text-align: center;line-height: 24px;font-weight: 400;margin-top:22px;">
                全品类美国本土现货货盘一件代发</div>
             </div>
          
          </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeSection3',
    components:{
      
    },
    data:function() {
      return {
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  