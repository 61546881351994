<template>
    <div class="distribution-section2" style="margin-top:110px;display: flex;flex-flow:column nowrap; justify-content:center">
          <div class="section2-column distribution-column" style="display:flex;flex-flow:row nowrap;align-items:center;justify-content:center;"> 
             <div v-bind:style="{ 'background-image': 'url(' + bgImage1 + ')' }" style="position:relative;width: 400px;height: 440px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
               <img class="" src="@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02图标01.png" style="width: 88px;margin-top: 90px;"/>
               <div style="font-size: 22px;color: #333333;letter-spacing: 0;text-align: center;line-height: 22px;font-weight: 600;margin-top:53px;">全美尾程派送</div>
               <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;margin-top:22px;padding: 0px 45px;">
                海外仓本土发货，库内响应快，
                </div>
                <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;padding: 0px 45px;">
                24小时出入库
                </div>
             </div>

             <div  v-bind:style="{ 'background-image': 'url(' + bgImage2 + ')' }" style="position:relative;width: 400px;height: 440px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin-left:30px;">
               <img src="@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02图标02.png" style="width: 88px;margin-top: 90px;"/>
               <div style="font-size: 22px;color: #333333;letter-spacing: 0;text-align: center;line-height: 22px;font-weight: 600;margin-top:53px;">一件代发</div>
               <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;margin-top:22px;padding: 0px 45px;">
                一件代发标准作业，可以提供多品类，
               </div>
               <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;padding: 0px 45px;">
                多SKU高效管理
               </div>
             </div>

             <div v-bind:style="{ 'background-image': 'url(' + bgImage3 + ')' }" style="position:relative;width: 400px;height: 440px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin-left:30px;">
               <img src="@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02图标03.png" style="width: 68px;margin-top: 90px;"/>
               <div style="font-size: 22px;color: #333333;letter-spacing: 0;text-align: center;line-height: 22px;font-weight: 600;margin-top:53px;">本地仓储</div>
               <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;margin-top:22px;padding: 0px 45px;">
                标准库内作业规范化流程，无隐藏收费，</div>
                <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;padding: 0px 45px;">
                 费用低，物流成本可控</div>
             </div>

          </div>
          
          <div class="section2-column distribution-column" style="display:flex;flex-flow:row nowrap;align-items:center;justify-content:center;margin-top:40px;"> 
             <div  v-bind:style="{ 'background-image': 'url(' + bgImage4 + ')' }" style="position:relative;width: 400px;height: 440px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
               <img src="@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02图标04.png" style="width: 70px;margin-top: 95px;"/>
               <div style="font-size: 22px;color: #333333;letter-spacing: 0;text-align: center;line-height: 22px;font-weight: 600;margin-top:53px;">账号打单</div>
               <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;margin-top:22px;padding: 0px 45px;">
                订单信息推送给仓库分拣、包装、贴面单，</div>
                <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;padding: 0px 45px;">
                下架出库后，等待交给当地快递公司</div>
             </div>

             <div  v-bind:style="{ 'background-image': 'url(' + bgImage5 + ')' }" style="position:relative;width: 400px;height: 440px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin-left:30px;">
               <div style="height: 88px;margin-top: 90px;"/>
               <div style="font-size: 22px;color: #333333;letter-spacing: 0;text-align: center;line-height: 22px;font-weight: 600;margin-top:45px;">退货换标</div>
               <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;margin-top:22px;padding: 0px 45px;">
                如买家需退换货，可将货物退回海外仓，</div>
                <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;padding: 0px 45px;">
                海外仓收到退件后进行换货补发等售后处理</div>
             </div>

             <div v-bind:style="{ 'background-image': 'url(' + bgImage6 + ')' }" style="position:relative;width: 400px;height: 440px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin-left:30px;">
               <img src="@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02图标06.png" style="width: 70px;margin-top: 90px;"/>
               <div style="font-size: 22px;color: #333333;letter-spacing: 0;text-align: center;line-height: 22px;font-weight: 600;margin-top:53px;">库内分销</div>
               <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;margin-top:22px;padding: 0px 45px;">
                多渠道派送，运力充足时效快，</div>
                <div style="font-size: 16px;color: #666666;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;padding: 0px 45px;">
                派送范围广， 稳定性高</div>
             </div>
             
          </div>
         
    </div>
  </template>
  
  <script>
  import bgImage1 from '@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02背景01.png'
  import bgImage2 from '@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02背景02.png'
  import bgImage3 from '@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02背景03.png'
  import bgImage4 from '@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02背景04.png'
  import bgImage5 from '@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02背景05.png'
  import bgImage6 from '@/assets/img/distribution/distribution-section2/02分销商城01mydepot海外仓02背景06.png'
  export default {
    name: 'DistributionSection2',
    components:{
      
    },
    data:function() {
      return {
                bgImage1,
                bgImage2,
                bgImage3,
                bgImage4,
                bgImage5,
                bgImage6,
            };
    },      
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  