import Home from '@/views/Home.vue';
import Warehouse from '@/views/Warehouse.vue';
import QuickContact from '@/views/QuickContact.vue';
import LiveBroadcast from '@/views/LiveBroadcast.vue'
import BusinessConsult from '@/views/BusinessConsult.vue';
import { createRouter, createWebHashHistory } from 'vue-router'
 
const routes = [
	{
		path: '/',
		name: 'MyDepot-首页',
		component: Home,
	}, 
	{ path: '/warehouse', name: 'MyDepot-海外仓', component: Warehouse }, 
	{ path: '/quickcontact', name: 'MyDepot-达人快速建联', component: QuickContact }, 
	{ path: '/live' , name: 'MyDepot-直播代播', component: LiveBroadcast },
	{ path: '/consult' , name: 'MyDepot-业务咨询', component: BusinessConsult }
  ]
 
const router = createRouter({
	history: createWebHashHistory(),
	routes
  })
  
  router.beforeEach((to, from, next) => {
	document.title = to.name;
	next();
  });

 export default router
  