<template>
  <router-view />
</template>

<script>
import { WOW } from 'wowjs';

export default {
  name: "App",
  components: {},
  mounted() {
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
    new WOW({
      offset: 1, // default: 0
      live: true // default: true
    }).init();

    // 监听离开顶部和回到顶部,查询.header元素添加或者删除类名.header-fixed
    window.addEventListener("scroll", () => {
      const header = document.querySelector(".header");
      if (window.scrollY > 0) {
        header.classList.add("header-fixed");
      } else {
        header.classList.remove("header-fixed");
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },
  methods: {
    checkMobile() {
      if (this.isMobile()) {
        document.body.classList.add("mobile");
      } else {
        document.body.classList.remove("mobile");
      }
    },
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
* {
  box-sizing: border-box;
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
}
</style>
