<template>
    <div class="home-section6" style="background: #0f054c;display: flex;flex-flow:column nowrap; justify-content:center;position: relative;">
      <div style="display: flex;flex-flow:column nowrap; justify-content:center;padding:6% 8%;">
        <router-link to="/" style="text-align: left;">
          <img src="@/assets/img/home/home-section1/00header-logo-white@2x.png" style="width: 252px;"/>
        </router-link>
        <!-- <img src="@/assets/img/home/home-section1/00header-logo-white@2x.png" style="width: 252px;"/> -->
      <div style="background: rgb(255, 255, 255);font-size: 16px;color: rgb(15, 5, 76);letter-spacing: 0px;text-align: center;line-height: 16px;font-weight: 400; width: 310px;height: 42px;padding: 14px;margin: 28px 0;border-radius: 3px;">
        TikTok美区最大货盘 - 达人撮合平台
      </div>
      <div class="bottom-qrcode" style="display: flex;flex-flow:row nowrap; justify-content: flex-start;align-items: flex-start;">
        <div style="display: flex;flex-flow:row nowrap; justify-content: flex-start;align-items: baseline;flex:1">
          <div style="display: flex;flex-flow:column nowrap; justify-content:center">
            <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin:8px 0;text-align:left;">
              <router-link to="/warehouse" style="color: #FFFFFF;text-decoration: none;">MyDepot海外仓</router-link>
            </div>
            <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin:8px 0;text-align:left;">
              <router-link to="/quickcontact" style="color: #FFFFFF;text-decoration: none;">达人快速建联</router-link>
            </div>
            <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin:8px 0;text-align:left;">
              <router-link to="/live" style="color: #FFFFFF;text-decoration: none;">直播代播</router-link>
            </div>
            <!-- <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin:8px 0;text-align:left;">美国本土货盘</div> -->
          </div>
          <div style="display: flex;flex-flow:column nowrap; justify-content:center;margin-left:120px;">
            <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin:8px 0;text-align:left;">
              <a href="https://www.mydepot.com"  style="color: #FFFFFF;text-decoration: none;">分销商城</a>
            </div>
            <!-- <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin:8px 0;text-align:left;">关于我们</div> -->
            <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin:8px 0;text-align:left;">
              <router-link to="/consult" style="color: #FFFFFF;text-decoration: none;">业务咨询</router-link>
            </div>
          </div>
        </div>
        <div class="bottom-qrcode-body" style="display: flex;flex-flow:row nowrap; justify-content: flex-end;align-items: baseline;flex:1">
          <div style="display: flex;flex-flow:column nowrap; justify-content:center;margin:0 16px;">
            <img src="@/assets/img/home/home-section6/00底部二维码01企业微信@2x.png" style="width:102px;margin:auto;"/>
            <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin-top:16px;">业务代表企业微信</div>
          </div>
          <div style="display: flex;flex-flow:column nowrap; justify-content:center;margin:0 16px;">
            <img src="@/assets/img/home/home-section6/00底部二维码02公众号.png" style="width:102px;margin:auto;"/>
            <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin-top:16px;">微信公众号</div>
          </div>
          <div style="display: flex;flex-flow:column nowrap; justify-content:center;margin:0 16px;">
            <img src="@/assets/img/home/home-section6/00底部二维码03视频号.png" style="width:102px;margin:auto;"/>
            <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin-top:16px;">微信视频号</div>
          </div>
          <div style="display: flex;flex-flow:column nowrap; justify-content:center;margin:0 16px;">
            <img src="@/assets/img/home/home-section6/00底部二维码04抖音.png" style="width:102px;margin:auto;"/>
            <div style="font-size: 14px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin-top:16px;">抖音官方号</div>
          </div>
        </div>
      </div>
      </div>

      <div style="background: #312772;display: flex;flex-flow:column nowrap;padding: 20px 8%;">
        <!-- <div class="bottom-text" style="display: flex;flex-flow:row nowrap;color: #FFFFFF;letter-spacing: 0;line-height: 30px;margin:10px 0;">
          <span style="font-size: 18px;font-weight: 500;">中国总部</span>
          <span class="bottom-line" style="font-size: 16px;font-weight: 400;margin:0 10px;"> | </span>
          <span style="font-size: 16px;font-weight: 400; "> 深圳千亚国际供应链科技有限公司</span>
          <span style="font-size: 14px;font-weight: 400;margin:0 20px;color: #C8C5FF;"> 电话：0755-83997996</span>
          <span style="font-size: 14px;font-weight: 400;margin:0 20px;color: #C8C5FF;"> 邮箱：lala@mydepot.cn</span>
          <span style="font-size: 14px;font-weight: 400;margin:0 20px;color: #C8C5FF;"> 地址：广东省深圳市福田区八卦五街  锦文创客大厦4楼</span>
        </div> -->
        <div class="bottom-text" style="display: flex;flex-flow:row nowrap;color: #FFFFFF;letter-spacing: 0;line-height: 30px;margin:10px 0;">
          <span style="font-size: 18px;font-weight: 500;">美国总部</span>
          <span class="bottom-line" style="font-size: 16px;font-weight: 400;margin:0 10px;"> | </span>
          <span style="font-size: 16px;font-weight: 400; "> MyDepot Inc</span>
          <span style="font-size: 14px;font-weight: 400;margin:0 20px;color: #C8C5FF;"> Number：+1(678)685 1029</span>
          <span style="font-size: 14px;font-weight: 400;margin:0 20px;color: #C8C5FF;"> Email： Info@mydepot.com</span>
          <span style="font-size: 14px;font-weight: 400;margin:0 20px;color: #C8C5FF;"> Office：1826 Doan Way STE B, Norcross, GA 30093</span>
        </div>
      </div>
      <div style="font-size: 16px;color: #FFFFFF;letter-spacing: 0;line-height: 16px;font-weight: 400;margin:50px 0; text-align:center;">粤ICP备2022073879号-1</div>
    </div>
  </template>
  
  <script>
 
  export default {
    name: 'PageFooter',
    components:{
      
    },
    data:function() {
      return {
 
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  