<template>
    <div class="distribution-section2" style="padding: 110px 100px">
      <div class="section2-bg" v-bind:style="{ 'background-image': 'url(' + bgImage1 + ')' }" style="display: flex;align-items: center;position: relative;height: 620px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;">
        <div class="mobile-show" style="position: relative;">
          <img src="@/assets/img/live-broadcast/02分销商城03直播代播02背景01.png" alt="">
          <div style="display:flex;flex-flow:column nowrap;position: absolute; left:55%;width:488px; top: 25%;text-align:left;">
            <div style="font-size: 40px;color: #333333;letter-spacing: 0;line-height: 32px;font-weight: 400;">
              本土直播基地
            </div>
            <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-top:40px;">
              每个独立的直播间配备专业的直播设备，打造品牌专属带货场景，只有融入本地文化的直播间才能吸引观众眼球。
            </div>
          </div>
        </div>
        <div class="mobile-hide" style="display:flex;flex-flow:column nowrap;position: absolute; left:55%;width:488px;text-align:left;">
            <div style="font-size: 40px;color: #333333;letter-spacing: 0;line-height: 32px;font-weight: 400;">
              本土直播基地
            </div>
            <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-top:40px;">
              每个独立的直播间配备专业的直播设备，打造品牌专属带货场景，只有融入本地文化的直播间才能吸引观众眼球。
            </div>
        </div>
      </div>
      <div class="section2-bg" v-bind:style="{ 'background-image': 'url(' + bgImage2 + ')' }" style="display: flex;align-items: center;position: relative;height: 620px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin-top:50px;">
        <div class="mobile-show" style="position: relative;">
          <img src="@/assets/img/live-broadcast/02分销商城03直播代播02背景02.png" alt="">
          <div style="display:flex;flex-flow:column nowrap;position: absolute; left:55%;width:488px; top: 25%;text-align:left;">
            <div style="font-size: 40px;color: #333333;letter-spacing: 0;line-height: 32px;font-weight: 400;">
              MyDepot 签约本地主播
            </div>
            <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-top:40px;">
              MyDepot本土网红直播基地有30名+经过专业培训的本地直播主播，我们的主播覆盖美妆、服装、居家好物、3C、时尚饰品等热门行业。
            </div>
          </div>
        </div>
        <div class="mobile-hide" style="display:flex;flex-flow:column nowrap;position: absolute;left:55%;width:520px;text-align:left;">
            <div style="font-size: 40px;color: #333333;letter-spacing: 0;line-height: 32px;font-weight: 400;">
              MyDepot 签约本地主播
            </div>
            <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-top:40px;">
              MyDepot本土网红直播基地有30名+经过专业培训的本地直播主播，我们的主播覆盖美妆、服装、居家好物、3C、时尚饰品等热门行业。
            </div>
        </div>
      </div>
      <div class="section2-bg" v-bind:style="{ 'background-image': 'url(' + bgImage3 + ')' }" style="display: flex;align-items: center;position: relative;height: 620px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin-top:50px;">
        <img class="mobile-show" src="@/assets/img/live-broadcast/02分销商城03直播代播02背景03.png" alt="">
        <div style="display:flex;flex-flow:column nowrap;position: absolute; left: 0;width:500px;text-align:left;background: #391BF1;box-shadow: 0px 0px 16px 0px rgba(57,27,241,0.09);border-radius: 8px;padding:40px 25px 40px 40px;">
            <div style="font-size: 24px;color: #FFFFFF;letter-spacing: 0;line-height: 48px;font-weight: 400;">
              根据产品特性，定制直播方案，精通不同人货场的组合以及直播的引流玩法，根据直播数据沉淀情况输出策略。
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import bgImage1 from "@/assets/img/live-broadcast/02分销商城03直播代播02背景01.png"
  import bgImage2 from "@/assets/img/live-broadcast/02分销商城03直播代播02背景02.png"
  import bgImage3 from "@/assets/img/live-broadcast/02分销商城03直播代播02背景03.png"
  export default {
    name: 'DistributionSection2',
    components:{
      
    },
    data:function() {
      return {
                bgImage1,
                bgImage2,
                bgImage3
              };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  