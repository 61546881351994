<template>
  <a-form
    ref="formRef"
    :model="formState"
    name="basic"
    :label-col="{ span: 5, style: { textAlign:'left' } }"
    :wrapper-col="{ span: 16 }"
    autocomplete="off"
    style="margin-top:16px"
  >

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <label style="width: 85px;text-align:left;">咨询业务 </label>
    <a-select
      class="form-item-input"
      ref="select"
      v-model:value="formState.consultType"
      style=" width: 420px; text-align:left;"
      @focus="focus"
      @change="handleChange"
      size="large"
    >
      <a-select-option value="供货商入驻">供货商入驻</a-select-option>
      <a-select-option value="产品分销">产品分销</a-select-option>
      <a-select-option value="海外仓">海外仓</a-select-option>
      <a-select-option value="达人建联">达人建联</a-select-option>
      <a-select-option value="直播代播">直播代播</a-select-option>
      <a-select-option value="其他">其他</a-select-option>
    </a-select>
  </div>

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <label style="width: 85px;text-align:left;">咨询内容 </label>
    <a-form-item class="form-item-input" name="consultContent" style="text-align: left;" :rules="[{ required: true, message: '请输入咨询内容' }]"
    >
      <a-input v-model:value="formState.consultContent" placeholder="请输入..." style="height:40px;width: 350px;"/>
    </a-form-item>
  </div>

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <label style="width: 85px;text-align:left;">联系人 </label>
    <a-form-item class="form-item-input" name="contactName" style="text-align: left;" :rules="[{ required: true, message: '请输入联系人' }]"
    >
      <a-input v-model:value="formState.contactName" placeholder="请输入..." style="height:40px;width: 350px;"/>
    </a-form-item>
  </div>

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <label style="width: 85px;text-align:left;">公司名称 </label>
    <a-form-item class="form-item-input" name="companyName" style="text-align: left;" :rules="[{ required: true, message: '请输入公司名称' }]"
    >
      <a-input v-model:value="formState.companyName" placeholder="请输入..." style="height:40px;width: 350px;"/>
    </a-form-item>
  </div>

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <label style="width: 85px;text-align:left;">联系电话 </label>
    <a-form-item class="form-item-input" name="contactNumber" style="text-align: left;" :rules="[{ required: true, message: '请输入联系电话' }]">
      <a-input type="number" v-model:value="formState.contactNumber" placeholder="请输入..." style="height:40px;width: 350px;"/>
    </a-form-item>
  </div>

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <label style="width: 85px;text-align:left;">邮箱地址 </label>
    <a-form-item class="form-item-input" name="contactEmail" style="text-align: left;" :rules="[{ required: true, type: 'email', message: '请输入邮箱地址',}]">
      <a-input v-model:value="formState.contactEmail" placeholder="请输入..." style="height:40px;width: 350px;"/>
    </a-form-item>
  </div>

    <a-form-item :wrapper-col="{ span: 16 }">
      <a-button @click="onSubmit" type="primary" html-type="submit" style="padding:8px 196px;height:unset; font-size: 16px;color: #391BF1;letter-spacing: 0;text-align: center;font-weight: 600;background:  #FFFFFF;border-radius: 32px;margin-top:10px;">
        提交
      </a-button>
    </a-form-item>

    <div style="margin-top:20px;font-size: 14px;color: #FFFFFF;letter-spacing: 0;text-align: center;line-height: 14px;font-weight: 200;">
      我们的工作人员将会在24小时内联系你 
    </div>
  </a-form>
</template>
<script setup>
import { Modal } from 'ant-design-vue';
import axios from 'axios';
import { h, reactive, ref, toRaw } from 'vue';

const formRef = ref();

const formState = reactive({
  consultType:'供货商入驻',
  consultContent:'',
  contactName: '',
  companyName:'',
  contactNumber:'',
  contactEmail: '',
});

const onSubmit = () =>{
  formRef.value
    .validate()
    .then(() => {
      submitForm();
    })
    .catch(error => {
      console.log('error', error);
    });
}

const submitForm = () =>{
  const formData = toRaw(formState);
  const url = 'https://api.autobrowsers.com/ab/api/v1/md_consult';
  axios.post(url, {...formData})
  .then(function (response) {
    if(response.status == 200){
      showSuccessModal();
    }else {
      showFailedModal();
    }
  })
  .catch(function () {
    showFailedModal();
  });
}

const showSuccessModal = ()=>{
  Modal.success({
    title: '提交成功',
    content: h('div', {}, [
      h('p', '我们的业务员将尽快联系您'),
    ]),
    centered:true,
    onOk:()=>{
      resetForm();
    }
  });
}

const showFailedModal = ()=>{
  Modal.error({
    title: '提交失败',
    content: '对您造成不便，敬请谅解，请稍后再试',
    centered:true,
  });
}
const resetForm = () => {
  formRef.value.resetFields();
};
</script>
<style scoped>
label {
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 400;
}
.ant-form-item {
  margin-bottom: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}
</style>
