<template>      
  <div class="header" style="height:80px;">
    <div class="header-left"> 
      <div class="header-logo">
        <router-link to="/">
          <img class="section1-logo" width="105" height="21" src="@/assets/img/home/home-section2/00header-logo-blue.png" style="margin-top: 8px;"/>
          <img class="section1-logo-fixed" width="105" height="21" src="@/assets/img/home/home-section2/00header-logo-blue.png" style="margin-top: 8px;"/>
        </router-link>
      </div>
    <!-- <a-dropdown :trigger="['click']">
      <router-link to="/" class="ant-dropdown-link" style="text-decoration:none;margin-left: 120px;">业务介绍</router-link>
    </a-dropdown> -->
    <a-dropdown :trigger="['click']" placement="bottom">
      <a class="ant-dropdown-link" @click.prevent>
        业务介绍
        <DownOutlined />
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item key="0" class="dropdown-item">
            <router-link to="/warehouse" class="dropdown-item-link" style="color: #391BF1;font-weight: 400;">MyDepot  海外仓</router-link>
          </a-menu-item>
          <a-menu-item key="1" class="dropdown-item">
            <router-link to="/quickcontact" class="dropdown-item-link">达人快速建联</router-link>
          </a-menu-item>
          <a-menu-item key="3" class="dropdown-item">
            <router-link to="/live" class="dropdown-item-link" >直播代播</router-link>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <a href="https://www.mydepot.com" class="dropdown-item-link" style="margin-left:90px;text-decoration: none;font-weight: 400;">分销商城</a>
    <a-dropdown :trigger="['click']">
      <router-link to="/consult" class="ant-dropdown-link" style="color: #391BF1;  border-bottom: 2px #391BF1 solid;font-weight: 500;text-decoration:none">业务咨询</router-link>
    </a-dropdown>
    </div>
    <div class="header-right">
      <img src="@/assets/img/distribution/distribution-section1/00登录blue.png" style="margin-right: 10px;"/>
      <a-button class="distribution-login"><a href="https://www.mydepot.com/#/login" class="login-link">登录</a></a-button>
    </div>
  </div>
 
  <div class="consult-title" style="display:flex;flex-flow:row nowrap;align-items:center;padding:90px 190px 110px 100px;">
      <div class="left" style="flex:1;padding: 0 50px;">
          <img src="@/assets/img/business-consult/03业务咨询01背景@2x.png" style="width:100%;min-width:300px;"/>
      </div>
      <div class="right" style="position:relative;margin-left: 100px; width: 500px;">
      <div style=" background: #391BF1;padding: 40px;
            box-shadow: inset 0px 0px 24px 0px rgba(193,220,255,1);
            border-radius: 40px;display:flex;flex-flow:column nowrap; ">
 
            <div style="font-size: 22px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            line-height: 22px;
            font-weight: 600;text-align:left;" >Contact Us</div>
        
            <ConsultForm />
    
      </div></div>
      
  </div>
  
</template>
  
  <script>
  import bgImage from '@/assets/img/business-consult/03业务咨询01背景@2x.png';
import ConsultForm from '@/components/business-consult/ConsultForm.vue';
  export default {
    name: 'HomeSection1',
    components:{
      ConsultForm
    },
    data:function() {
      return {
          bgImage
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
  .distribution-section1 {
    position: relative;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  .section1-image1 {
    position:absolute;
    top: 0;
    height:800px;
  }
  .header {
    display: flex;
    flex-flow: row nowrap;
    width:100%;
    padding: 30px 120px;
    z-index: 2;
    height: 50px;
  }
  .ant-dropdown-link{
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
    font-weight: 400;
    margin-left: 90px;
  }
  .dropdown-item-link {
    display: block;
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
    font-weight: 200;
    padding: 15px;
  }
   
  .header-left {
    display: flex;
    align-items: center;
    flex:1;
  }
  .header-right {
    width:auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .distribution-login {
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding:3px 8px;
    height: unset;
    background: #391BF1;
    border-radius: 12px;
  }
  .description {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: 100%;
    margin-top: 155px;
    padding: 0px 170px;
  }
  .description-left {
    display:flex;
    flex-flow:column;
  }
  .description-left-title {
    display:flex;  
    font-size: 28px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 500;
    margin-top: 42px;
  }
  .description-left-desc {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 500;text-align:left;margin-top:31px;
  }
  .login-link:hover {
    color:#FFFFFF
  }
  </style>
  