<template>
    <div class="distribution-section5" style="display: flex;flex-flow:row nowrap; justify-content:center;margin-top:110px;">
      <div class="left" style="display: flex;flex-flow:column nowrap; justify-content:center;flex:1;background: #F4F8FF;padding: 130px 100px 130px 130px;">
          <div style="display: flex;flex-flow:row nowrap; justify-content:flex-start;">
              <img src="@/assets/img/distribution/distribution-section5/02分销商城01mydepot海外仓05图标01.png" style="height: 100px;width: 95px;"/>
              <div class="img-text" style="display: flex;flex-flow:column nowrap; justify-content:center;text-align:left;margin-left:50px;">
                <div style="font-size: 32px;color: #333333;letter-spacing: 0;line-height: 32px;font-weight: 600;">精细化订单管理</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:24px;">
                  仓库在24H内出库及时率均达99.4%以上，拒绝延迟发货，保障客户订单履约。
                </div>
              </div>
          </div>

          <div style="display: flex;flex-flow:row nowrap; justify-content:flex-start; margin-top: 40px;">
              <img src="@/assets/img/distribution/distribution-section5/02分销商城01mydepot海外仓05图标02.png" style="height: 100px;width: 95px;"/>
              <div class="img-text" style="display: flex;flex-flow:column nowrap; justify-content:center;text-align:left;margin-left:50px;">
                <div style="font-size: 32px;color: #333333;letter-spacing: 0;line-height: 32px;font-weight: 600;">合规化数字化经营</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:24px;word-break: keep-all;">
                  全链路数据化监控，备货无风险，仓配全链路的价格竞争优势，产品定价合理，降低卖家备货成本。
                </div>
              </div>
          </div>

          <div style="display: flex;flex-flow:row nowrap; justify-content:flex-start;margin: 40px 0;">
              <img src="@/assets/img/distribution/distribution-section5/02分销商城01mydepot海外仓05图标03.png" style="height: 100px;width: 95px;"/>
              <div class="img-text" style="display: flex;flex-flow:column nowrap; justify-content:center;text-align:left;margin-left:50px;padding-left: 9px;">
                <div style="font-size: 32px;color: #333333;letter-spacing: 0;line-height: 32px;font-weight: 600;">个性定制化服务</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:24px;">
                  可提供多场景定制化差异化服务，满足客户多场景需求，降低物流成本，提高卖家运营效率，拓宽销售渠道，提升商品周转/利用率。
                </div>
              </div>
          </div>

          <div style="display: flex;flex-flow:row nowrap; justify-content:flex-start;">
              <img src="@/assets/img/distribution/distribution-section5/02分销商城01mydepot海外仓05图标04.png" style="height: 100px;width: 95px;"/>
              <div class="img-text" style="display: flex;flex-flow:column nowrap; justify-content:center;text-align:left;margin-left:50px;">
                <div style="font-size: 32px;color: #333333;letter-spacing: 0;line-height: 32px;font-weight: 600;">本地化售后无忧</div>
                <div style="font-size: 18px;color: #666666;letter-spacing: 0;line-height: 32px;font-weight: 400;margin-top:24px;">
                  专人对接，国内外团队全流程接力。可根据客诉内容对于逆向退回商品提供研判信息，客服一对一服务，灵活响应客户需求。
                </div>
              </div>
          </div>
      </div>

      <div class="right" style="display: flex;flex-flow:column nowrap; justify-content:center;width:800px;padding:0 200px 0 150px;">
        <div style="font-size: 32px;color: #391BF1;letter-spacing: 0;line-height: 32px;font-weight: 400;text-align:left;">MyDepot</div>
        <div style="font-size: 48px;color: #391BF1;letter-spacing: 0;line-height: 32px;font-weight: 600;margin-top:40px;text-align:left;">服务优势</div>
        <div style="font-size: 24px;color: #333333;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-top:40px;text-align:left;">
          Mydepot Inc，是一家专注经营美国仓配一体化服务的大型综合跨境物流公司。我们始终坚持通过极致的供应链服务和销售渠道能力，助力中国商家布局美国市场。
        </div>
        <div style="font-size: 24px;color: #333333;letter-spacing: 0;line-height: 48px;font-weight: 400;margin-top:20px;text-align:left;">
          公司具备跨境全链路的资源整合能力，致力于为跨境电商卖家在国际供应链管理环节中提供海外仓储配送及物流综合解决方案，并能够满足客户的全链路定制化需求。
        </div>
        <!-- <div class="right-phone" style="font-size: 55px;color: #333333;letter-spacing: 0;line-height: 48px;font-weight: 900;text-align:left;font-family: fantasy;margin-top:40px;">
          0755-83997996
        </div> -->
      </div> 
    </div>
  </template>
  
  <script>
 
  export default {
    name: 'DistributionSection5',
    components:{
      
    },
    data:function() {
      return {
 
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  