<template>
    <div class="distribution-section7" v-bind:style="{ 'background-image': 'url(' + bgImage1 + ')' }" style="position:relative;width: 100%;height: 670px;background-repeat: no-repeat;background-position: center;background-size: 100% 100%;margin-top:110px;">
        <div style="font-size: 40px;color: #FFFFFF;letter-spacing: 0;line-height: 40px;font-weight: 600;position: absolute;top: 100px;left: 120px;">24小时出仓，最快2天签收</div>
    </div>
  </template>
  
  <script>
   import bgImage1 from '@/assets/img/distribution/distribution-section7/02分销商城01mydepot海外仓06背景@2x.png'
  
  export default {
    name: 'DistributionSection7',
    components:{
      
    },
    data:function() {
      return {
          bgImage1,
        };
    },
    props: {
     
    }
  }
  </script>
   
  <style scoped>
   
  </style>
  