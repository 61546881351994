<template>
  <a-form
    ref="formRef"
    :model="formState"
    name="basic"
    :label-col="{ span: 5, style: { textAlign:'left' } }"
    :wrapper-col="{ span: 18 }"
    autocomplete="off"
    style="margin-top:16px"
  >

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <div style="display: flex;flex-flow: row nowrap; align-items: center;">
      <div style="height:15px; border: 1.5px #391BF1 solid;"></div>
      <label style="width: 66px;;text-align:left;margin-left:8px;">联系人 </label>
    </div>
    <a-form-item class="form-item-input" name="contactName" :rules="[{ required: true, message: '请输入联系人' }]" style="text-align: left;">
      <a-input v-model:value="formState.contactName" placeholder="请输入..." style="height:40px;width: 340px;"/>
    </a-form-item>
  </div>

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <div style="display: flex;flex-flow: row nowrap; align-items: center;">
      <div style="height:15px; border: 1.5px #391BF1 solid;"></div>
      <label style="width: 66px;;text-align:left;margin-left:8px;">公司名称 </label>
    </div>
    <a-form-item class="form-item-input" name="companyName" :rules="[{ required: true, message: '请输入公司名称' }]" style="text-align: left;">
      <a-input v-model:value="formState.companyName" placeholder="请输入..." style="height:40px;width: 340px;"/>
    </a-form-item>
  </div>

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <div style="display: flex;flex-flow: row nowrap; align-items: center;">
      <div style="height:15px; border: 1.5px #391BF1 solid;"></div>
      <label style="width: 66px;;text-align:left;margin-left:8px;">联系电话 </label>
    </div>
    <a-form-item class="form-item-input" name="contactNumber" :rules="[{ required: true,  message: '请输入联系电话' }]" style="text-align: left;">
      <a-input v-model:value="formState.contactNumber" placeholder="请输入..." type="number" style="height:40px;width: 340px;"/>
    </a-form-item>
  </div>

  <div class="form-item" style="display:flex;flex-flow:row nowrap;align-items:baseline;margin: 16px 0;">  
    <div style="display: flex;flex-flow: row nowrap; align-items: center;">
      <div style="height:15px; border: 1.5px #391BF1 solid;"></div>
      <label style="width: 66px;;text-align:left;margin-left:8px;">邮箱地址 </label>
    </div>
    <a-form-item class="form-item-input" name="contactEmail" :rules="[{required: true, type: 'email',message:'请输入有效邮箱' }]" style="text-align: left;">
      <a-input v-model:value="formState.contactEmail" placeholder="请输入..." style="height:40px;width: 340px;"/>
    </a-form-item>
  </div>
 
    <a-form-item :wrapper-col="{ span: 16 }">
      <a-button @click="onSubmit" type="primary" html-type="submit" class="submit-btn" style="margin-top: 16px;padding: 10px 195px;height:unset; font-size: 16px;color: #FFFFFF;letter-spacing: 0;text-align: center;font-weight: 500;background: #391BF1;border-radius: 32px;">提交</a-button>
    </a-form-item>
  </a-form>
</template>
<script setup>
import { Modal } from 'ant-design-vue';
import axios from 'axios';
import { h, reactive, ref, toRaw } from 'vue';
const formRef = ref();

const formState = reactive({
  consultType:'供货商入驻',
  consultContent:'联系注册',
  contactName: '',
  companyName:'',
  contactNumber: '',
  contactEmail: '',
});
 
const onSubmit = () =>{
  formRef.value
    .validate()
    .then(() => {
      submitForm();
    })
    .catch(error => {
      console.log('error', error);
    });
}

const submitForm = () =>{
  const formData = toRaw(formState);
  const url = 'https://api.autobrowsers.com/ab/api/v1/md_consult';
  axios.post(url, {...formData})
  .then(function (response) {
    if(response.status == 200){
      showSuccessModal();
    }else {
      showFailedModal();
    }
  })
  .catch(function () {
    showFailedModal();
  });
}
 
const showSuccessModal = ()=>{
  Modal.success({
    title: '提交成功',
    content: h('div', {}, [
      h('p', '我们的业务员将尽快联系您'),
    ]),
    centered:true,
    onOk:()=>{
      resetForm();
    }
  });
}

const showFailedModal = ()=>{
  Modal.error({
    title: '提交失败',
    content: '对您造成不便，敬请谅解，请稍后再试',
    centered:true,
  });
}
const resetForm = () => {
  formRef.value.resetFields();
};
</script>
<style scoped>
.ant-form-item {
  margin-bottom: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}
</style>
