<template>
   <HomeSection1/>
   <HomeSection2/>
   <HomeSection3/>
   <HomeSection4/>
   <HomeSection5/>
   <PageFooter/>
</template>

<script>
import HomeSection1 from '@/components/home/HomeSection1.vue';
import HomeSection2 from '@/components/home/HomeSection2.vue';
import HomeSection3 from '@/components/home/HomeSection3.vue';
import HomeSection4 from '@/components/home/HomeSection4.vue';
import HomeSection5 from '@/components/home/HomeSection5.vue';
import PageFooter from '@/components/common/PageFooter.vue';
 
export default {
  name: 'HomePage',
  components:{
    HomeSection1,
    HomeSection2,
    HomeSection3,
    HomeSection4,
    HomeSection5,
    PageFooter
  },
  props: {
   
  }
}
</script>
 
<style scoped>

</style>
