<template>
  <DistributionSection1/>
  <DistributionSection2 />
  <DistributionSection3 />
  <DistributionSection4 />
  <DistributionSection5 />
  <PageFooter />
</template>

<script>
import DistributionSection1 from '@/components/distribution/quick-contact/DistributionSection1.vue';
import DistributionSection2 from '@/components/distribution/quick-contact/DistributionSection2.vue';
import DistributionSection3 from '@/components/distribution/quick-contact/DistributionSection3.vue';
import DistributionSection4 from '@/components/distribution/quick-contact/DistributionSection4.vue';
import DistributionSection5 from '@/components/distribution/quick-contact/DistributionSection5.vue';
import PageFooter from '@/components/common/PageFooter.vue';

export default {
 name: 'QuickContact',
 components:{
    DistributionSection1,
    DistributionSection2,
    DistributionSection3,
    DistributionSection4,
    DistributionSection5,
    PageFooter
 },
 props: {
  
 }
}
</script>

<style scoped>
</style>
