<template>
  <DistributionSection1/>
  <DistributionSection2 />
  <PageFooter />
</template>

<script>
import DistributionSection1 from '@/components/business-consult/ConsultSection1.vue';
import DistributionSection2 from '@/components/business-consult/ConsultSection2.vue';
import PageFooter from '@/components/common/PageFooter.vue';

export default {
 name: 'QuickContact',
 components:{
  DistributionSection1,
  DistributionSection2,
  PageFooter
 },
 props: {
  
 }
}
</script>

<style scoped>

</style>
