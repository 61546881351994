<template>
    <div class="home-section4" style="margin-top:110px;background: #F4F6FF;padding: 110px 190px;">
        <div style="display: flex;flex-flow:column nowrap; justify-content:center">
        <div style="font-size: 16px;color: #391BF1;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 400;">MyDepot</div>
          <div style="font-size: 32px;color: #391BF1;letter-spacing: 0;text-align: center;line-height: 32px;font-weight: 600;margin-top:8px;">入驻流程</div>
          <div class="section4-content" style="display: flex;flex-flow:row nowrap; justify-content:center;margin-top:80px;">
            <div class="left" style="display:flex;flex-flow:column nowrap;flex:1;text-align:left;">
                  <div style="font-size: 32px;color: #333333;letter-spacing: 0;line-height: 56px;font-weight: 600;">适合卖家类型：</div>
                  <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 56px;font-weight: 400; margin-top: 16px;">1.品牌商，想要拓展TikTok等更多销售渠道的卖家</div>
                  <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 56px;font-weight: 400;">2.有美国本土库存需要快速起量的卖家</div>
                  <div style="font-size: 24px;color: #666666;letter-spacing: 0;line-height: 56px;font-weight: 400;">3.已有TikTok账号，对达人建联和直播缺少资源的卖家</div>
                  <div style="font-size: 24px;color: #333333;letter-spacing: 0;line-height: 56px;font-weight: 600;">...</div>
                  <div style="font-size: 24px;color: #333333;letter-spacing: 0;line-height: 56px;font-weight: 600;">想要了解更多，与我们业务代表联系 </div>
                  <a-button type="link" href="https://www.mydepot.com/#/register?identityType=Sup" style="width:160px;height:50px;font-size: 24px;color: #FFFFFF;letter-spacing: 0;text-align: center;font-weight: 600;background:#391BF1;border-radius: 8px;margin-top:42px;">
                    马上入驻 
                  </a-button>
            </div>
            
            <div class="right" style="display:flex;flex-flow:column nowrap;background: #FFFFFF;border-radius: 40px;width: 495px;height: fit-content;padding:16px 36px;margin-left: 20px;">
              <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" style="background-color: #FFFFFF; ">
                <template #expandIcon="{ isActive }">
                  <img src="@/assets/img/home/home-section4/01首页04展开.png" :style="{transform:isActive ? 'rotate(0deg)' : 'rotate(180deg)'}" style="position: absolute;top: 10px;right: 0px;width: 28px;" />
              </template>
                <a-collapse-panel key="1" header="1.线上注册账号" class="collapse-header">
                  <p class="collapse-content">需要卖家提供手机号、邮箱用于注册账号，账号用于卖家后期登入卖家后台。</p>
                </a-collapse-panel>
                <a-collapse-panel key="2" header="2.卖家入驻材料提交" class="collapse-header">
                  <p class="collapse-content">在账号中按照指引填写入驻信息表单。完整提交后请点击“提交审核”按钮等待审核。</p>
              
                </a-collapse-panel>
                <a-collapse-panel key="3" header="3.资质审核" class="collapse-header">
                  <p class="collapse-content">
                    MyDepot工作人员会尽快审核您的材料，审核周期约1-2个工作日（遇节假日、周末、繁忙时段审核时间会顺延），请与业务代表联系，了解审核进度通知。如需补充/修改材料，请及时补充/修改，避免因长期未补充/修改被关闭。
                  </p>
        
                </a-collapse-panel>
                <a-collapse-panel key="4" header="4.上架销售"  class="collapse-header" >
                  <p class="collapse-content">
                    资料审核通过后，您即可登陆账号上架产品或选择我们的达人建联和直播服务开启您的业务。
                  </p>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
        </div>
    </div>
  </template>
  
<script setup>
import { ref } from 'vue';
const activeKey = ref(['1']);

</script>
   
<style scoped>
  .collapse-header {
  text-align: left;
  font-size: 24px;
  color: #333333;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 600;
  margin-top: 14px;
  padding-bottom:10px;
  }

  .collapse-content {
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 400;
  margin:0;
  }
  .ant-collapse .ant-collapse-content {
    border-top: none;
  }

</style>
